import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import { Link } from "gatsby";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  disabled: {
    cursor: "default",
    opacity: 0.65,
  },
  disabledText: {
    opacity: 0.3,
  },
  loader: {
    position: "absolute",
    top: 8,
    width: 35,
    height: 35,
    color: theme.palette.secondary.main,
    marginLeft: "auto",
    marginRight: "auto",
  },
  line: {
    position: "absolute",
    left: "0px",
    top: "0px",
    right: "0px",
    bottom: "0px",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.secondary.main,
    transition: "all 200ms ease",
    transform: "translateX(-130%)",
  },
  label: {
    zIndex: 10,
  },
  link: {
    textDecoration: "none",
    "&:visited": {
      textDecoration: "none",
    },
  },
  button: {
    borderRadius: 0,
    height: 52,
    minWidth: 120,
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: "#222",
    overflow: "hidden",
    color: "#fff",
    transition: "color 300ms ease-in-out",
    "&:hover": {
      color: "#222",
    },
  },
  buttonRoot: {
    textTransform: "none",
    fontSize: 16,
  },
  buttonOnHover: {
    animation: "$translate-on-hover 300ms",
    animationFillMode: "forwards",
    animationTimingFunction: "ease-in-out",
  },
  buttonOnLeave: {
    animation: "$translate-on-leave 300ms",
  },
  "@keyframes translate-on-hover": {
    "0%": { transform: "translateX(-130%)" },
    "100%": { transform: "translateX(0%)" },
  },
  "@keyframes translate-on-leave": {
    "0%": { transform: "translateX(0%)" },
    "100%": { transform: "translateX(130%)" },
  },
  loadingContainer: {
    position: "relative",
    display: "inline-flex",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingText: {
    color: theme.palette.background.paper,
  },
}));

const AnimatedButton = ({
  onClick,
  label,
  disabled,
  loading,
  id,
  size,
  href,
  className,
  loadingProgress,
  loaderClassName,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const handleOnHover = () => {
    const element = document.getElementById(id);
    element.classList.remove(classes.buttonOnLeave);
    element.classList.add(classes.buttonOnHover);
  };

  const handleOnLeave = () => {
    const element = document.getElementById(id);
    element.classList.replace(classes.buttonOnHover, classes.buttonOnLeave);
  };

  const button = (
    <ButtonBase
      variant="contained"
      color="primary"
      disableRipple
      disabled={disabled}
      className={clsx(
        classes.button,
        { [classes.disabled]: disabled },
        className
      )}
      onClick={handleClick}
      classes={{ root: classes.buttonRoot }}
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnLeave}
    >
      {!loading && <div className={classes.label}>{label}</div>}
      <div className={classes.line} id={id} />
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress
            size={size}
            className={clsx(classes.loader, loaderClassName)}
          />
          <Typography
            variant="caption"
            component="div"
            className={classes.loadingText}
          >
            {loadingProgress}%
          </Typography>
        </div>
      )}
    </ButtonBase>
  );

  if (href) {
    return (
      <Link to={href} className={classes.link}>
        {button}
      </Link>
    );
  }

  return button;
};

AnimatedButton.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  progress: PropTypes.number,
};

export default AnimatedButton;
