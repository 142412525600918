import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Img from "gatsby-image/withIEPolyfill";
import AnimatedButton from "./animated-button";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: "80px 8vw",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down(767)]: {
      height: "auto",
    },
  },
  intro: {
    width: "60%",
  },
  title: {
    marginTop: "80px",
    marginBottom: "20px",
    color: "#fff",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "5.55vw",
    lineHeight: "110%",
    fontWeight: "600",
    [theme.breakpoints.down(991)]: {
      fontSize: "56px",
      lineHeight: "125%",
    },
    [theme.breakpoints.down(479)]: {
      fontSize: "48px",
    },
  },
  description: {
    marginBottom: "40px",
    color: "#fff",
    fontFamily: "Roboto, sans-serif",
    opacity: 0.6,
    fontSize: "18px",
    lineHeight: "175%",
  },
  button: {
    border: "1px solid #fff",
    backgroundColor: "transparent",
    padding: "15px 32px 17px",
  },
  imageWrapper: {
    width: "40%",
    position: "absolute",
    left: "auto",
    top: 0,
    right: 0,
    bottom: "-50px",
    zIndex: 9,
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  children: {
    padding: "80px 6vw",
  },
}));

const SimplePage = ({ children, title, description, fluid }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.intro}>
          <h1 className={classes.title}>{title}</h1>
          <p className={classes.description}>{description}</p>
          <AnimatedButton
            label="Go to Homepage"
            id="homepage-button"
            className={classes.button}
            href="/"
          />
        </div>
        <div className={classes.imageWrapper}>
          <Img
            className={classes.image}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={fluid}
          />
        </div>
      </div>
      <div className={classes.children}>{children}</div>
    </>
  );
};

SimplePage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fluid: PropTypes.any.isRequired,
};

export default SimplePage;
