import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  text: {
    color: "#222",
    fontFamily: "Roboto, sans-serif",
    marginTop: "10px",
    marginBottom: "40px",
  },
}));

const Paragraph = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={clsx(classes.text, className)}>
      {children}
    </Typography>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Paragraph;
