import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "gatsby";

const linesCommon = {
  width: 24,
  height: 4.5,
  borderRadius: 0,
  position: "absolute",
  transitionProperty: "transform",
  transitionDuration: "0.15s",
  transitionTimingFunction: "ease",
};

const useStyles = makeStyles(theme => ({
  container: {
    position: "fixed",
    left: "auto",
    top: "0%",
    right: "0%",
    bottom: "auto",
    zIndex: "999",
    display: "flex",
    width: 80,
    height: 80,
    [theme.breakpoints.down("xs")]: {
      width: 60,
      height: 60,
    },
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    cursor: "pointer",
    margin: 0,
    border: "none",
  },
  burgerWrapper: {
    width: "24px",
    height: "22px",
    display: "inline-block",
    position: "relative",
  },
  lines: {
    display: "block",
    backgroundColor: "white",
    ...linesCommon,
    "&::before, &::after": {
      backgroundColor: "white",
      ...linesCommon,
      content: "''",
      display: "block",
    },
    "&::before": {
      top: -9,
      transition:
        "top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19)",
    },
    "&::after": {
      bottom: -9,
      transition:
        "top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear",
      top: -18,
    },
    top: "auto",
    bottom: 0,
    transitionDuration: "0.13s",
    transitionDelay: "0.13s",
    transitionTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
  },
  activeLines: {
    transform: "translate3d(0, -10px, 0) rotate(-45deg)",
    transitionDelay: "0.22s",
    transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    "&::before": {
      top: "0",
      transform: "rotate(-90deg)",
      transition:
        "top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1)",
    },
    "&::after": {
      top: "0",
      opacity: "0",
      transition:
        "top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear",
    },
  },
  menuOverlay: {
    position: "fixed",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%",
    zIndex: "99",
    display: "flex",
    padding: "80px 8vw",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    backgroundColor: theme.palette.primary.main,
    transformStyle: "preserve-3d",
    transition: "transform 500ms ease 0s",
    transform: "translateX(105%) translateY(0px) translateZ(0px)",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 60,
      flexDirection: "column",
    },
  },
  menuActive: {
    transform: "translateX(0%) translateY(0px) translateZ(0px)",
  },
  copyright: {
    position: "absolute",
    left: "8vw",
    top: "auto",
    right: "auto",
    bottom: "80px",
    width: "470px",
    opacity: "0",
    transform: "none",
    color: "#fff",
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "left",
    letterSpacing: "1px",
    transition: "opacity 200ms ease 0.5s",
    [theme.breakpoints.down("xs")]: {
      position: "static",
      marginTop: "40px",
      textAlign: "right",
    },
  },
  activeCopyright: {
    opacity: 0.3,
  },
  links: {
    opacity: 0,
    transition: "all 200ms ease 0.5s",
  },
  activeLinks: {
    opacity: 1,
  },
  link: {
    marginBottom: 10,
    display: "block",
    color: "#fff",
    textDecoration: "none",
    fontSize: 36,
    fontWeight: 400,
    lineHeight: "125%",
    fontFamilt: "'Work Sans', sans-serif",
    textAlign: "right",
    letterSpacing: "1px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  currentLink: {
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Menu = () => {
  const [isActive, setActive] = useState(false);
  const classes = useStyles();

  return (
    <>
      <button
        className={classes.container}
        type="button"
        onClick={() => setActive(prev => !prev)}
      >
        <div className={classes.burgerWrapper}>
          <span
            className={clsx(classes.lines, { [classes.activeLines]: isActive })}
          />
        </div>
      </button>
      <div
        className={clsx(classes.menuOverlay, {
          [classes.menuActive]: isActive,
        })}
      >
        <div
          className={clsx(classes.links, { [classes.activeLinks]: isActive })}
        >
          <Link
            to="/"
            activeClassName={classes.currentLink}
            className={classes.link}
          >
            Home
          </Link>
          <Link
            to="/physical-prints"
            activeClassName={classes.currentLink}
            className={classes.link}
          >
            Physical Prints
          </Link>
          <Link
            to="/about"
            activeClassName={classes.currentLink}
            className={classes.link}
          >
            About
          </Link>
          <Link
            to="/contact"
            activeClassName={classes.currentLink}
            className={classes.link}
          >
            Contact
          </Link>
          <Link
            to="/faq"
            activeClassName={classes.currentLink}
            className={classes.link}
          >
            FAQ
          </Link>
        </div>
        <div
          className={clsx(classes.copyright, {
            [classes.activeCopyright]: isActive,
          })}
        >
          Copyright @ imagineart.ai {new Date().getFullYear()}
        </div>
      </div>
    </>
  );
};

export default Menu;
