import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Footer from "./footer";
import Menu from "./menu";
import logo from "../images/logo.svg";
import "./layout.css";
import Toast from "./toast";
import * as Data from "../models/data";

// eslint-disable-next-line import/no-mutable-exports
export let smoothScroll;

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  smoothScroll = require("smooth-scroll")('a[href*="#"]', {
    speed: 800,
    easing: "easeInOutCubic",
    offset: -75,
  });
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 auto",
    width: "100%",
  },
  logoWrapper: {
    position: "absolute",
    left: 40,
    top: 40,
    zIndex: 8,
    textDecoration: "none",
    display: "inline-block",
    [theme.breakpoints.down(767)]: {
      left: "8vw",
      top: "8vw",
    },
    [theme.breakpoints.down(479)]: {
      top: 30,
    },
  },
  logo: {
    width: "6vw",
    height: "6vw",
    [theme.breakpoints.down(767)]: {
      width: 60,
      height: 60,
    },
  },
}));

const Layout = ({ children, error, setError }) => {
  const classes = useStyles();

  return (
    <>
      <Menu />
      <Toast
        setOpen={() => setError(null)}
        open={!!error}
        message={error || ""}
        severity="error"
      />
      <a href="/" className={classes.logoWrapper}>
        <img src={logo} alt="" className={classes.logo} />
      </a>
      <div className={classes.container}>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  error: Data.getError(state),
});

const mapDispatchToProps = dispatch => ({
  setError: Data.setError(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
