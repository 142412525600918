/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "80px 8vw",
    position: "relative",
  },
  siteMap: {
    color: "#222",
    lineHeight: "150%",
    marginBottom: "20px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "14px",
    fontWeight: "700",
    letterSpacing: "1px",
  },
  links: {
    width: "50%",
    [theme.breakpoints.down(767)]: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
    },
    "&::after": {
      clear: "both",
    },
    "&::after, &::before": {
      content: "' '",
      display: "table",
    },
  },
  linksSection: {
    position: "relative",
    float: "left",
    width: "50%",
    minHeight: "1px",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.down(767)]: {
      left: "auto",
      right: "auto",
    },
  },
  copyright: {
    fontFamily: "Roboto, sans-serif",
    color: "#222",
    position: "absolute",
    left: "auto",
    top: "auto",
    right: "8vw",
    bottom: "100px",
    opacity: "0.3",
    fontSize: "14px",
    lineHeight: "100%",
    [theme.breakpoints.down(991)]: {
      bottom: "80px",
    },
    [theme.breakpoints.down(767)]: {
      marginTop: "40px",
      position: "static",
    },
    [theme.breakpoints.down(479)]: {
      marginTop: "80px",
    },
  },
  link: {
    textDecoration: "none",
    fontFamily: "'Work Sans', sans-serif",
    color: "#222",
    fontSize: "14px",
    lineHeight: "200%",
    display: "block",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rightLink: {
    marginLeft: "20px",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.siteMap}>SITE MAP</div>
      <div className={classes.links}>
        <div className={classes.linksSection}>
          <Link className={classes.link} to="/about">
            About
          </Link>
          <Link className={classes.link} to="/contact">
            Contact
          </Link>
          <Link className={classes.link} to="/physical-prints">
            Physical Prints
          </Link>
        </div>
        <div className={classes.linksSection}>
          <Link className={clsx(classes.link, classes.rightLink)} to="/faq">
            FAQ
          </Link>
          <Link
            className={clsx(classes.link, classes.rightLink)}
            to="/terms-of-service"
          >
            Terms of Service
          </Link>
          <Link
            className={clsx(classes.link, classes.rightLink)}
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
      <div className={classes.copyright}>
        Copyright @ imagineart.ai {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Footer;
